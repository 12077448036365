<template>
    <v-row class="approve_banner px-2 py-3 blue-grey lighten-5 mb-10">
      <v-col col="2" sm="2" class="text-center">
        <v-avatar color="grey lighten-1" size="68">
          <v-icon dark medium>mdi-check-decagram</v-icon>
        </v-avatar>
      </v-col>
      <v-col col="7" sm="7" class="d-flex align-center">
        <div>
          <h3>Approve step : <span>{{ approve_status }}</span></h3>
            <ApproveLogs logtype="all" class="mt-2"/>
        </div>
      </v-col>
      <v-col col="3" sm="3" class="text-center">
        <v-btn 
          v-show="canDo && canDo.can.includes('approve')"
          class="ma-1" width="100" depressed dark small color="primary" @click="approve('approve')">Approve</v-btn>
        <v-btn 
          v-show="canDo && canDo.can.includes('approve')"
          class="ma-1" width="100" depressed dark small color="red darken-4" @click="rejectModal = true">Reject</v-btn>
        <v-dialog v-model="rejectModal" max-width="500px">
          <!-- <template v-slot:activator="{ on }">
              <v-btn class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-plus-circle</v-icon> Add
              </v-btn>
          </template> -->
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">Reject Message</span>
            </v-card-title>

            <v-card-text>
              <v-form >
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="reject_message" label="Message"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="rejectClose">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="approve('reject')">Submit Reject</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </v-col> 
    </v-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ApproveLogs from '@/components/drivers/approve_logs.vue';

export default {
    name: 'ProfileImage',
    components : {
      ApproveLogs
    },
    props: ['approve_status'],
    data: () => ({
      rejectModal: false,
      reject_message: '',
    }),
    methods:{
      ...mapActions(['approveDriver']),
      approve(type) {
        var data = {
                      'approveType' : type,
                      'message' : this.reject_message
                   };
        this.approveDriver(data);
      },
      rejectClose() {
        this.rejectModal = false
        this.reject_message = ''
      }
    },
    computed: {
      ...mapGetters(['canDo']),
      isReject() {
        return this.approve_status.search('Rejected');
      }
    }
}
</script>
<style scoped>
.approve_banner{
  color:#666;
}
.approve_banner h3{
  font-size: 18px;
  font-weight:500;
}
.approve_banner h3 span{
  font-size: 18px;
  font-weight:300;
}
</style>