<template>
    <div id="approve_logs">
        <ul>
            <!-- <li>[] เอกสารไม่ครบถ้วน </li> -->
            <li v-for="(log, index) in logMessages" :key="index">
                [ <span v-if="logtype == 'all'">{{log.step}} - </span> 
                    {{ moment(log.date).format("DD-MM-YYYY HH:mm")}} ] {{log.comment}}
            </li>
            <!-- {{logMessages}} -->
        </ul>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ApproveLogs',
    props: {
        logtype : {
            type: String,
            required: true,
            validator: function (value) {
                return [
                    'rejectonly',
                    'all'
                ].indexOf(value) !== -1
            },
            default: 'rejectonly'
        }
    },
    data: () => ({
    }),
    computed: {
      ...mapGetters(['approveLogs']),
      logMessages() {
        if( this.logtype == 'rejectonly') {
            let reject_id = [2,4];
            return this.approveLogs.filter(log => log.comment !== "" && reject_id.includes(log.step_id));
        } else {
            return this.approveLogs
        }
        
      }
    }
}
</script>
<style scoped>
#approve_logs ul li{
  font-size: 14px;
}
</style>